import React from 'react';
import { Helmet } from 'react-helmet';
import "./terms.scss";
class Terms extends React.Component {
 
  render() {
    return (
      <div className="showdow-box">
        <Helmet 
          title="Terms of Service | Club Afib"
          meta={[
            { name: 'description', content: 'The ClubAfib.com website, software application, and service (hereinafter “Club Afib”, “we”, “our”, “website”, “App”, or “Service”) exclusively belongs to Club Afib, Inc.  Club Afib grants you, the person using the website and/or App, the right to use our website, software applications, and services subject to the Terms of Service set forth below.' },
            { name: 'keywords', content: 'club, afib, terms, service' }
          ]}
          link={[{rel: 'canonical', href: 'https://clubafib.com/terms-and-conditions'}]}
        />
        <h1 style={{fontSize: "25px", textAlign:"center"}}>Club Afib Terms of Service</h1>
        <br></br>
        <p>Last Updated August 30, 2020.</p>
        <p>The ClubAfib.com website, software application, and service (hereinafter “Club Afib”, “we”, “our”, “website”, “App”, or “Service”) exclusively belongs to Club Afib, Inc.  Club Afib grants you, the person using the website and/or App, the right to use our website, software applications, and services subject to the Terms of Service set forth below.</p>
        <p>The Terms of Service and the Privacy Policy (collectively the “Agreements”) set forth the terms and conditions that apply to your access and use of Club Afib.  By using Club Afib, you acknowledge that you have read and understood the Agreements, and that you agree to be bound by the terms and conditions contained therein.  If you do not wish to be bound by the Agreements, you may not use the Club Afib website, App, or Service. </p>
        <p>By using Club Afib, you represent and warrant that you have reached the legal age of majority, are not under the age of 13, and must otherwise be legally eligible to be bound by the Agreements contained herein.  You may not use Club Afib if you are under the age of 13 or if you cannot or do not agree to be bound by this Agreement.</p>
        <p>These Agreements form legally binding contracts between you and Club Afib.  Please review these Agreements carefully.  Club Afib may update the Terms of Service or the Privacy Policy at any time without notification to you, so you should review these Agreements each time you access Club Afib.  Your continued use of Club Afib shall legally constitute acceptance of all revisions to the Agreements.  It is recommended that you save or print a local copy of the Agreements prior to use.</p>
        <p>Club Afib is designed for educational and wellness purposes, and is dedicated to streamlining your access to common healthcare needs.  Club Afib does not provide medical advice and does not control or interfere with a healthcare provider’s medical care to you.</p>
        
        <h2 style={{fontSize: "22px"}}>Intellectual Property Rights</h2>
        <p>Club Afib is the sole owner of the content and design on our websites and apps, which is protected under U.S. and international copyright laws.  All right, title, interest and ownership and intellectual property rights in and to the trademarks and the website, including by not limited to its look and feel, images, video, audio, data, content, software, and other media, material or information, other than user content, are and will remain the property of Club Afib. </p>
        <p>Subject to the terms and conditions of this Agreement, you are granted a personal, non-transferable, non-exclusive, and revocable right to access the website(s) or app and use the services that are subject to a valid subscription.  You may look at our content online, download information to your computer to read later, or print copies of pages for yourself, your family, or your friends but you may not remove any copyright notices from our content.  We reserve all rights, which means that you cannot sell our content, rewrite or modify it, redistribute it, use it on your own website, or use it for any commercial or non-personal purpose without Club Afib’s prior written permission.</p>
        <p>You acknowledge that any ideas, suggestions, concepts, processes or techniques which you provide to Club Afib shall, in perpetuity, become our property without any compensation or other consideration payable to you. Club Afib will own any test result, data, information, and/or other output generated by your use of this website, app, or service.
        </p>
        <p>With written permission, Club Afib may permit another organization to provide a link to Club Afib’s website. To request permission, please email Club Afib at support@clubafib.com.
        </p>
        <p>Club Afib uses original, licensed stock, and purchased photography on our websites and apps. The individuals portrayed in the images may be models, and their inclusion is not intended to imply any endorsement or association with any illness or condition.
        </p>

        <h2 style={{fontSize: "22px"}}>Submission of Content</h2>
        <p>You acknowledge and agree that you are solely responsible for all content you submit, provide or upload on Club Afib (“Content”) and any consequences of submitting, providing or uploading your Content.  We reserve the right to cooperate with any governmental investigation or as required by law or legal or administrative process, and to protect the rights and property of Club Afib, its users and other third parties.
        </p>
        <p>You represent and warrant that you own your Content, or have all the necessary licenses, rights, consents and permissions to grant the license set forth herein, and that its provision to us or our use thereof will not violate the copyrights, privacy rights, publicity rights, trademark rights, contract rights or any other intellectual property rights or other rights of any person or entity.
        </p>
        <p>Club Afib has no responsibility for and no obligation to monitor for appropriateness, and does not recommend or endorse any Content posted or uploaded by users.
        </p>

        <h2 style={{fontSize: "22px"}}>Content Review</h2>

        <p>Club Afib's objective is to provide high-quality health information through the content on our websites and apps. We define “content” as all text, images, graphics, tables, audio and video recordings, menu icons, bars, listings, indices, and functions that support content such as links, navigation, searches, and calculations.
        </p>
        <p>
        Every effort has been made to provide accurate and current information that will be useful to the reader.  Although much of the information provided on our websites and apps was authored by Club Afib, some information is provided through a contract with third parties.  Although Club Afib believes that information provided on our websites and apps to be accurate, we specifically disclaim any guarantees or warranties, whether expressed or implied. It is the responsibility of visitors to our websites to evaluate the information we provide and determine its relevancy and usefulness to them.
        </p>
        <p>
        Please contact Club Afib support@clubafib.com with any concerns about the content provided on our websites and apps.
        </p>

        <h2 style={{fontSize: "22px"}}>Privacy</h2>
        <p>
            Club Afib is committed to protecting your personal information. As described in our Privacy Policy, we keep your personal information confidential.  Club Afib complies with applicable laws, regulations, standards, and guidelines at all times.
        </p>
         <p>Our Privacy Policy is fully incorporated by reference. If you have any questions concerning the confidentiality of your personal information that has been entrusted to Club Afib, please contact Club Afib at support@clubafib.com.
        </p>
        
 
        <h2 style={{fontSize: "22px"}}>Collection and Use of Identifiable Information</h2>
        <p>
        Club Afib may ask you to provide identifiable personal information while using our websites and apps. This information is collected and used only for the purposes indicated. If we wish to use your personal information for a new or different purpose, we will offer you the means to consent to this purpose at the point where the use of your personal information is requested. Except as requested by you, or as indicated on the web page or app where your information is requested, we do not disclose your identifiable personal information to other organizations.
        </p>

        <h2 style={{fontSize: "22px"}}>Children’s Privacy</h2>
        <p>Club Afib is committed to protecting the privacy of children. Our websites and apps are not intended or designed to attract children under the age of thirteen. We do not knowingly collect, maintain, or use personal information from children under thirteen years of age, and no part of our websites are directed to children under the age of thirteen. If you learn that your child has provided us with personal information without your consent, you may alert us at support@clubafib.com.  If we learn that we have collected any personal information from children under thirteen years old, we will promptly take steps to delete such information.</p>

        <h2 style={{fontSize: "22px"}}>Use of “Cookies”</h2>
        <p>As you browse Club Afib’s websites and apps, “cookies” may be placed on your computer so that we can understand your interests and enhance your experience. Cookies do not collect information about you. Rather, a cookie may contain limited information, such as the type of browser you are using, your general location, your device’s IP address, the date and time of your visit, your web domain type, and your activity on our website. None of this information in any form or combination will be traced back to you by Club Afib.
        </p>
        <p>
            As you browse Club Afib websites and apps, your IP address and/or http requests (URLs) may be used to gather navigation and usage patterns across various browsers and devices. The purpose of such tracking is to help Club Afib understand how its website(s)/app(s) are being accessed and to enhance usability. These IP addresses and http requests (URLs) are not used to trace back and re-identify specific users.
        </p>
        <p>
            Club Afib may also use cookies as a security measure to help provide you with a secure online experience. Most browsers allow you to delete, block, or otherwise restrict cookies on your computer. However, this may affect your ability to use Club Afib websites and apps.
        </p>
        <p>
            Club Afib may share cookie information with our advertising and marketing partners so that we can present you with relevant advertising on other sites or with marketing information based on your previous interaction with our websites and apps. Our advertising partners may have their own privacy policies.
        </p>

        <h2 style={{fontSize: "22px"}}>User Accounts</h2>
        <p>You agree and understand that you are responsible for maintaining the confidentiality of your password which, together with your email address, allows you to access Club Afib. That email address and password, together with any or other user information you provide, form your "Profile Information" and allows you to access your account ("Account"). You agree to provide true, accurate, current and complete information about yourself and to immediately provide updated information as your circumstances change. You may not misrepresent your Profile Information.
        </p>
        <p>
            You agree to provide true, accurate, current and complete information about yourself and to immediately provide updated information as your circumstances change. You may not misrepresent your Profile Information.
        </p>
        <p> 
            Your right to access and use Club Afib is personal to you and is not transferable by you to any other person or entity. You are solely responsible for all activities that occur under your Account or under your Profile Information. If you become aware of any unauthorized use of your Account or Profile Information, you are responsible for notifying us immediately.
        </p>
        <p> 
            You are responsible for the secure and proper use of any Account that you establish on our websites and apps. You are also responsible for any actions taken utilizing your user account(s). Club Afib strongly encourages you to not share your username and password. Club Afib takes no responsibility for, and disclaims all liability for damages arising from, your sharing of your account(s) with others, or your account(s) being compromised. If at any time you feel that your user account has been compromised or that your information has been inappropriately disclosed or used in any way, you should immediately (a) change your password, then (b) contact Club Afib. Club Afib may suspend or discontinue your account(s) without prior notice if we have reason to believe that your account has been compromised or misused.
        </p>
        <p>
            By providing us with your email address, you agree to receive all required notices electronically, to that email address. It is your responsibility to update or change your email address, as appropriate.
        </p>

        <h2 style={{fontSize: "22px"}}>Online Forms</h2>
        <p>
            Club Afib may ask you for personal information while you are visiting our website. We ask for this information only to deliver materials that you have requested, to respond to a question you have asked, or to provide you with a product or service.
        </p>
        <p>
            When you enter data into an online form on our website, the personal information you type is protected and securely transmitted to us through a method such as a Secure Sockets Layer (SSL). SSL is the standard method that websites use to protect visitors' information by coding or "encrypting" everything from credit card transactions to enrollment data.
        </p>
        <p>
            All of the health information that you provide to us through this website is subject to the protections outlined in our Privacy Policy.
        </p>

        <h2 style={{fontSize: "22px"}}>Limitation of Liability</h2>
        <p>
            Under no circumstances shall Club Afib, any Club Afib affiliates, or any Club Afib licensor or supplier be liable in any way for your use of our website(s) or apps, including, but not limited to, any errors or omissions in any content, any infringement of the intellectual property rights or other rights of third parties, or for any loss or damage of any kind incurred as a result of the use of such website or app.
        </p>
        <p>
            In no event shall Club Afib or any of its affiliates be liable for any direct, indirect, special, punitive, indirect, incidental or consequential damages of any kind, including, but not limited to damages resulting from: (i) personal injury, wrongful death, loss of use, loss of profits, interruption of service, or loss of data; or (ii) mistakes, omissions, interruptions, deletion of files, errors, defects, delays in operation or transmission, or any failure of performance, whether or not limited to acts of god, communication failure, theft, destruction or unauthorized access to your records, programs, or services, and whether in any action in warranty, contract, or tort (including but not limited to negligence or breach), or otherwise arising out of or in any way connected with the use of, or the inability to use, your account or any service accessed through your account or any material or information contained in, accessed through, or products purchased on, our websites or apps.  You acknowledge and agree that Club Afib and its affiliates’ aggregate liability to you for any claim of damages, losses, fees, charges, expenses, or liabilities, and in circumstances where the foregoing limitation is finally determined to be unavailable, shall not exceed the fee paid by you for the good or services that gave rise to the claim.
        </p>

        <h2 style={{fontSize: "22px"}}>Warranty Disclaimer</h2>
        <p>Your use of any Club Afib website or app (including any affiliated account) is at your sole risk and is provided on an “as is” and “as available” basis. Club Afib expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the following:
        </p><p>    •  Warranties of merchantability, fitness for a particular purpose, and non-infringement;
        </p><p>    •  Warranties that (i) Club Afib’s website(s) and app will meet your requirements, (ii) Club Afib’s website(s) and apps will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of Club Afib’s website(s) and apps will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained through Club Afib’s website(s) or apps will meet your expectations, and (v) any errors associated the Club Afib’s website(s) or apps will be corrected;
        </p><p>    •  Any inaccuracies or defects in the information, software, communication lines, the Internet or your Internet service provider (ISP), computer hardware or software, or any other service or device that you use to access our websites and apps; and
        </p><p>   •  Warranties regarding advice and information obtained by you through Club Afib’s website(s) and apps — except as expressly stated in the terms associated with the website or app.
            
        </p><p>    You shall be solely and fully responsible for any use or disclosure of information caused by you or any person using your username and password. Club Afib does not assume any responsibility for any loss, damages, or liabilities arising from the failure of telecommunications infrastructure, the internet, or your misuse of any protected health information, advice, ideas, information, instructions, or guidelines accessed through Club Afib’s website(s) and apps. Should you have reason to believe that your information is not accurate, you should contact Club Afib immediately.
        </p>

        <h2 style={{fontSize: "22px"}}>Indemnification</h2>
        <p>You agree to indemnify, defend, and hold harmless Club Afib, its affiliates and its and their respective owners, investors, contractors, officers, directors, members, managers, partners, shareholders, licensors, suppliers, customers, employees, agents, third-party advertisers, technology providers and service providers from and against any loss, damage, cost, or liability (including, reasonable attorneys' fees) resulting from or relating in any way to a third-party claim, demand, action or proceeding that arises out of this Agreement or website, including but not limited to: (a) your use, non-use, or misuse of Club Afib and any of your Content; (b) your breach or alleged breach of this Agreement; (c) your violation of any rights, including intellectual property rights, of a third party; (c) your violation or alleged violation of any laws; or (d) your use or exploitation of any information obtained from Club Afib.  We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Club Afib. We will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
        
        <h2 style={{fontSize: "22px"}}>Security</h2>
        <p>Club Afib is committed to compliance with all federal, state, and local laws, as well as applicable regulations, standards, and guidelines established by government agencies and accepted accrediting organizations. Club Afib uses industry-accepted practices and technologies to secure our websites and apps, however, you should take appropriate precautions to protect personal and confidential information, and you should use your devices/apps in a secure and responsible manner.
        </p><p>
        Club Afib is not responsible for the security of your devices.  We will provide a secure transmission method for you to send to us your personal information. While such secure transmission methods provide reasonable protections against unauthorized access, if you have concerns regarding the transmission of sensitive information, you should consider using non-electronic communication methods.
        </p>

        <h2 style={{fontSize: "22px"}}>Acceptable Use</h2>
        <p>By using Club Afib’s websites and/or Apps, you agree to not access or use your account in an unlawful manner or for an illegitimate purpose, or in any manner that is not consistent with these terms. You shall not post, use, store, or transmit (a) a message or information under a false name; (b) information that is knowingly wrong or deceptive, unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening, or hateful to any person; and/or (c) information that infringes or violates any of the intellectual property rights of others or the privacy or publicity rights of others. You shall not attempt to disrupt the operation of our websites or apps by any method, including through the use of viruses, Trojan horses, worms, time bombs, denial of service attacks, flooding, or spamming. You shall not use your account in any manner that could damage, disable, or impair our website or app. You shall not attempt to gain unauthorized access to any user accounts, computer systems, or networks through hacking, password mining, or any other means. You shall not use any robot, scraper, or other similar means to access your account for any purpose.</p>
        <h2 style={{fontSize: "22px"}}>Links to other Sites</h2>
        <p>
        Links may be provided to non-Club Afib websites. If you decide to access these linked websites, you do so entirely at your own risk. Club Afib has no responsibility or control over the content or security of these websites, and makes no representations and gives no warranties with respect to the accuracy or completeness of any information contained therein. The inclusion of any link does not imply affiliation, endorsement, or adoption of the linked website or its content, nor of the organization hosting the website. Club Afib asks that you take appropriate precautions to ensure that whatever links you select are free from viruses and other types of malware. You agree that Club Afib shall not be responsible for any loss or damage of any kind incurred because of your access or use of these websites.
        </p>

        <h2 style={{fontSize: "22px"}}>
        Social Media
        </h2>
        <p>
        Club Afib encourages open dialogue and appreciates the importance of the visitor experience. In certain cases, Club Afib may give you the opportunity to post information on one of our websites or other social media sites in which Club Afib participates (such as Facebook). We encourage you to share your story, to "like" any post, and to share with your friends using “share” links. Discussions, questions, and commentary are welcome, and Club Afib asks that posts be respectful.
        </p><p>
            Club Afib shall not be held responsible for content submitted by other social media site users. Club Afib also reserves the right, but is not obligated, to remove any posts, graphics, comments, videos, photos, or any other content that:
            </p><p>    •         Is libelous, defamatory, or disparaging
            </p><p>•         Violates another party’s copyright, trademark, or other intellectual property rights
            </p><p>•         Affects the privacy rights of any of our patients, including the person posting the content, or of any Club Afib employee
            </p><p>•         Condones or promotes illegal activity
            </p><p>•         Is inaccurate, misleading, fraudulent, or deceptive
            </p><p>•         Uses harsh, obscene, hateful, or threatening language
            </p><p>•         Is spam, or is intended to cause technical disruptions to a Club Afib website or social media site
            </p><p>•         Offers unauthorized advice or tips
            </p><p>•         Markets commercial products or solicits monetary contributions to any organization
            </p><p>•         Is off-topic or excessive
            </p><p>•         Violates any applicable law
            
            </p><p>  Club Afib is not obligated to respond to any post or request for information or advice made on a social media site. Any health information provided is for informational purposes only and is not a substitute for professional medical advice.
 
        </p>

        <h2 style={{fontSize: "22px"}}>Electronic Communications Terms</h2>
        <p>At the discretion of Club Afib, we may communicate with you using various electronic communications tools, including:
        </p><p>   •         Email - Email or any message sent via the internet using a standard protocol called “Simple Mail Transfer Protocol” or “SMTP.” Email based on SMTP is not encrypted and cannot be considered secure. Club Afib will use reasonable means to protect the privacy of your health information sent by email but, since it is not encrypted, Club Afib cannot guarantee that messages sent by you will remain confidential prior to delivery. Accordingly, Club Afib suggests that you do not send or receive sensitive or confidential information by email if you have concerns regarding confidentiality.
        </p><p>   •         Secure Messaging - Club Afib may provide a secure means for you to communicate electronically with Club Afib through a secure portal or messaging system. In such cases, Club Afib will let you know that the portal or messaging system is secure.
        </p><p>   •         Texting  - Club Afib communicates with members through text messages on a limited basis, and only with prior approval. Typically, Club Afib text messages are intended to be general in nature, such as appointment reminders. In limited circumstances, we may agree to communicate additional information with you through text messaging. Club Afib will use reasonable means to protect the privacy of your information sent by text messages. As text messages are not encrypted, Club Afib cannot guarantee that the message you send will remain confidential prior to its delivery. Accordingly, Club Afib suggests that you do not send or receive sensitive or confidential information by text messaging if you have concerns regarding confidentiality.
        </p><p>   •         Online Forms - At times, Club Afib may ask you for personal information while you are visiting our websites or using our apps. We ask for this information only to deliver materials that you have requested, to respond to a question you have asked, or to provide you with a product or service. When you enter data into an online form on our website or app, the personal information you type is protected and securely transmitted to us.
</p>


        <h2 style={{fontSize: "22px"}}>Risks and Your Responsibility</h2>
        <p>
        Electronic communications may contain your personal information, including your personal health information. If you decide to use such means to communicate with Club Afib, you should be aware of the following risks and/or your responsibilities:
        </p><p>    •         Club Afib will use industry best practices to secure electronic communications with you. However, certain types of internet communications are not secure or private, and unauthorized people may be able to intercept, read, and possibly modify the content of these communications. For example, as described above, email is not considered secure because it is not encrypted.
        </p><p>    •         You must protect your accounts, passwords, and electronic devices against access by unauthorized people. Club Afib will not be liable if you or anyone else inappropriately uses your account(s). If you learn that your account has been compromised, it is your responsibility to notify Club Afib.
        </p><p>   •         Since electronic communication tools can be used to spread viruses, you should install and maintain virus protection software on your computing devices.
        </p><p>   •         Since electronic communication may be copied, printed, and/or forwarded by anyone who receives your communication, you should be careful regarding to whom you send such communications.
        </p><p>    •         As your employer may claim ownership of, or the right to access, an email account issued to you by your employer, you should avoid using an employer-issued email account to communicate with Club Afib. Club Afib will not be liable for any loss of privacy or confidentiality if you are using an employer-issued email account.

        </p>

        <h2 style={{fontSize: "22px"}}>Conditions for Communicating Electronically with Club Afib</h2>

        <p>
        By communicating electronically with Club Afib, you agree that:
        </p><p>    •         Club Afib may forward your communications as appropriate for reimbursement, billing, and other related reasons. As such, Club Afib staff members, other than the recipient, may have access to communications that you send.
        </p><p>    •         You should not use electronic communication tools to communicate with Club Afib if there is an emergency, or if you require an answer in a short period of time. For a medical emergency, you should dial 911 or go immediately to a hospital and/or emergency room.
        </p><p>    •         You will carefully consider the risk of using electronic communication tools to send sensitive medical information including, but not limited to, information regarding sexually transmitted diseases, HIV/AIDS, mental health, developmental disability, or substance abuse. You should consider other forms of communication to discuss such types of sensitive medical information.
        </p><p>   •         Club Afib reserves the right to save your communications.
        </p><p>   •         If you provide an email address to Club Afib, we may use that email address to communicate with you for a variety of purposes, such as billing related matters.

        </p>

        <h2 style={{fontSize: "22px"}}>
        Electronic Communication Instructions
        </h2>
        <p>When using electronic communication tools to communicate with Club Afib:
        </p><p>     •         You shall immediately notify Club Afib of changes to your email address, cell phone number, or preference for communicating.
        </p><p>     •         You shall include in the body of the communication your name and such other information as is necessary for Club Afib to identify you.
</p>

        <h2 style={{fontSize: "22px"}}>Device Compatibility</h2>
        <p>Club Afib does not guarantee that every device (smartphone, tablet, PC, etc.) or internet browser is capable of properly performing all functions of all Club Afib websites and apps.</p>

        <h2 style={{fontSize: "22px"}}>Cancellation and Termination</h2>
        <p>Club Afib reserves the right at any time, and without cost, charge or liability, to terminate this Agreement at its sole discretion for any reason, including, but not limited to, a failure to comply with the terms of this Agreement. Notice of termination will be provided to the e-mail address you currently have on file.
        </p><p>
            Club Afib reserves the right to deny access to anyone, including, but not limited to, those users who use proxy servers and/or IP addresses residing in certain geographical areas outside of Canada and the United States. We reserve the right to terminate any portion of the website or App at any time, for any reason, with or without notice to you.
            </p>

        <h2 style={{fontSize: "22px"}}>Refunds</h2>
        <p>
        If Club Afib made a mistake somewhere, we will work with you to quickly resolve the matter. Club Afib does not offer refunds outside of an internal error.  Under no circumstances shall Club Afib or its affiliates be held responsible for overdraft charges incurred from your financial institution as a result of any purchases or renewals through or in connection with our websites, apps or services. It is the sole responsibility of you as a customer to ensure that overdraft or similar fees from your financial institution do not occur by taking the necessary action to avoid them. Furthermore, Club Afib is not liable for any fees incurred due to billing errors.
        </p><p>
        Subscriptions are billed on a monthly recurring or annual basis depending on the plan selected. Your credit card will be debited the full purchase amount on the date of your purchase. For an annual plan, the subsequent renewals will be billed in twelve month increments from initial purchase, until your annual Subscription is canceled. For monthly plans, the subsequent renewals will be billed in monthly increments, beginning one month after the initial purchase, until your monthly Subscription is canceled. We will not refund "unused" portions of a Subscription. If you decide to cancel after 3 days of initial payment, we will simply stop all further billing and your Subscription will expire at the end of your current billing period.
        </p><p>A user may receive a refund of his/her initial signup payment if and only if they cancel the applicable paid service within 3 calendar days of account creation and have not initiated any use of the purchase or paid subscription.
        
        </p><p>A user may receive a refund of his/her most recent renewal payment if and only if they cancel the applicable paid service within one (1) calendar day of the renewal charge and request a refund by contacting support@clubafib.com.  No other refunds or credits will be provided by Club Afib unless otherwise required by law.
 
        </p>

        <h2 style={{fontSize: "22px"}}>Dispute Resolution and Arbitration</h2>
        <p>
        In the unlikely event that Club Afib has not been able to resolve a dispute it has with you after attempting to do so informally (in its sole discretion), you agree to resolve any claim, dispute, or controversy (excluding any claims we might have for injunctive or other equitable relief) arising out of or relating to the Terms of Service, the website, or the services through binding arbitration by the American Arbitration Association (“ AAA” ) in Bristol County, Massachusetts, under the commercial rules then in effect for the AAA, except as provided herein. The award rendered by the arbitrator shall include costs of arbitration, but each party shall be responsible for their own attorneys’ fees and expert witness fees. Nothing in this section shall be deemed as preventing Club Afib from seeking injunctive or other equitable relief from the courts as necessary to protect any of Club Afib’s proprietary interests.
        </p><p>
        All claims must be brought by the visitor or user in his or her individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding, including class action arbitration.  You further agree that you and Club Afib are each waiving the right to a trial by jury and to participate in a class action. 
        </p><p>
        Any cause of action or claim you may have arising out of or relating to the terms of service, the website, app, or the services, must be commenced within one (1) year after the cause of action accrues or such cause of action or claim shall be permanently barred.
 
        </p>

        <h2 style={{fontSize: "22px"}}>Severability</h2>
        <p>If any portion of this Agreement is deemed unlawful, void or unenforceable by any arbitrator or court of competent jurisdiction, this Agreement as a whole shall not be deemed unlawful, void or unenforceable, but only that portion of this Agreement that is unlawful, void or unenforceable shall be stricken from this Agreement.</p>
        
        <h2 style={{fontSize: "22px"}}>Club Afib Contact Information</h2>
        <p>If you need to contact Club Afib for any reason, you may do so at support@clubafib.com.</p>


      </div>
    )
  }
}

export default Terms;