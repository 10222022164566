import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import './home.scss';
import { NavLink } from 'react-router-dom';
import VideoPlayer from '../../components/videoplayer';
import ItemsCarousel from 'react-items-carousel';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Videos } from '../../constant';
import logowhite from '../../resources/images/logo_white.png';
import { getArticles, getCategories } from '../../redux/actions';
import {Helmet} from "react-helmet";

class Home extends Component {
	state = {
    activeVideoIndex:0,
  }

  componentDidMount() {
    this.props.getArticles();
    this.props.getCategories();
  }
  
  filterArticles = (category) => {
    return this.props.articles.filter(item => item.category === category);
  }

  generateURL = (item) => {
		let titleSplit = item.title.replace(/[^a-zA-Z0-9 ]/g, '');
    titleSplit = titleSplit.replace(/\s+$/, '');
    titleSplit = encodeURI(titleSplit);
    titleSplit = titleSplit.toLowerCase().replace(/%20/g, '-');
		let param = titleSplit + "-" + item.id;
		return `/article/${param}`;
	}

  render() {
    const { activeVideoIndex } = this.state;
    return (
      <div className="home-page">
        <Helmet 
          title="Club Afib"
          meta={[
            { name: 'description', content: 'Learn all about Atrial Fibrillation, Finally Manage it, and Get the Support you Need by Joining our Community of Club Members and Afib Experts' },
            { name: 'keywords', content: 'club, afib' }
          ]}
          link={[{rel: 'canonical', href: 'https://clubafib.com'}]}
        />

        <Container className="v-r">
          <div className="article-parts home-section v-r">
            <h2>Understand your AFib</h2>
            <p>Learn about AFib, how to reduce your<br />symptoms, and avoid its dangers</p>
            <div className="show-web-flex v-r">
              {this.props.categories.map((item, index) => <div className="articles-row" key={index}>
                <NavLink to={`/articles?category=${item.id}`} className="article-item header">
                  <div className={`article-content v-c`} style={{background: item.color}}>
                    <img src={logowhite} className="white-logo" alt="whitelogo" />
                    <p className="header-title">{item.title}</p>
                  </div>
                </NavLink>
                {this.filterArticles(item.id).map((article, articleIndex) => <NavLink to={this.generateURL(article)} className="article-item" key={articleIndex}>
                  <div className="article-content v-r">
                    <img src={article.banner} alt="banner" className="banner" />
                    <div className={`article-title`} style={{background: item.color}}>{article.title}</div>
                  </div>
                </NavLink>)}
              </div>)}
            </div>
            <div className="show-mobile-flex articles-row">
              {this.props.categories.map((item, index) => <NavLink to={`/articles?category=${item.id}`} className="article-item header" key={index}>
                <div className={`article-content v-c`} style={{background: item.color}}>
                  <img src={logowhite} className="white-logo" alt="whitelogo" />
                  <p className="header-title">{item.title}</p>
                </div>
              </NavLink>)}
            </div>
          </div>
        </Container>
        
        <Container className="home-container">
          <div className="home-section videos-area">
            <ItemsCarousel
              classes={{itemsWrapper: 'items-wrapper', itemsInnerWrapper: 'inner-wrapper'}}
              infiniteLoop={true}
              numberOfCards={1}
              slidesToScroll={1}
              alwaysShowChevrons={true}
              showSlither={false}
              firstAndLastGutter={false}
              activePosition={'center'}
              leftChevron={<div className="carousel-btn left-btn v-c h-c"><KeyboardArrowLeft fontSize="large"/></div>}
              rightChevron={<div className="carousel-btn right-btn v-c h-c"><KeyboardArrowRight fontSize="large" /></div>}
              activeItemIndex={activeVideoIndex}
              requestToChangeActive={value => this.setState({ activeVideoIndex: value })}
            >
              {Videos.map((item, index) => <VideoPlayer info={item} key={index} originIndex={index} activeIndex={activeVideoIndex}/>)}
            </ItemsCarousel>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
    loggedin: state.auth.loggedin,
    articles:state.article.articles,
    categories:state.category.categories
	}
}

export default connect(mapStateToProps, { getArticles, getCategories })(Home);
