import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';
import Logo from '../../components/logo';
import './header.scss';
import { authLogout, toggleSidebar } from '../../redux/actions';

class Header extends Component {
	state = {
    show_menu: false
  }
  
  signout = () => {
    this.props.authLogout()
  }

  toggleBar = () => {
    this.props.toggleSidebar(true)
  }

  render() {
    const { show_menu } = this.state;
    return (
      <div className="home-header">
        <Container className="v-c">
          <div className="logo-part v-c show-web-flex">
            <Logo />
          </div>
          <Logo className="show-mobile-flex"/>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
    loggedin: state.auth.loggedin,
	}
}

export default connect(mapStateToProps, { authLogout, toggleSidebar })(Header);
